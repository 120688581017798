<template>
  <div>
    <div>
      <a-card style="margin-bottom: 24px">
        <!-- 按钮 -->
        <a-row :gutter="0" style="line-height: 3">
          <a-col :md="24" :sm="24">
            <a-form layout="inline">
              <a-month-picker
                :value="queryParam.momentDate"
                :allowClear="false"
                @change="searchMonth"
                placeholder="选择月份"
              />
              <a-select
                style="width: 175px;margin-left: 0.5em;"
                v-model="queryParam.cityId"
                placeholder="请选择"
                @change="searchBrandList"
              >
                <a-select-option v-for="item in userCities" :key="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
              <a-select
                style="width: 175px;margin-left: 0.5em;"
                v-model="queryParam.facilityBrand"
                placeholder="请选择"
                @change="searchStoreList"
              >
                <a-select-option
                  v-for="item in brandNameList"
                  :key="item.code"
                  >{{ item.optionText }}</a-select-option
                >
              </a-select>
              <a-checkbox
                :checked="queryParam.allFacility"
                @change="checkStore"
                style="margin-left: 0.5em;"
              ></a-checkbox>
              <a-select
                :disabled="queryParam.allFacility"
                mode="multiple"
                style="width: 175px;margin-left: 0.2em;"
                v-model="queryParam.facilityIdList"
                :placeholder="!!queryParam.allFacility ? '全部店铺' : '请选择'"
              >
                <a-select-option v-for="item in storeList" :key="item.id">{{
                  item.optionText
                }}</a-select-option>
              </a-select>
            </a-form>
          </a-col>
        </a-row>
      </a-card>
      <a-card style="margin-bottom: 24px">
        <a-spin :spinning="dataLoading">
          <!-- <a-col :md="4" :sm="24">
            <a-button
              style="margin-left: 8px"
              @click="queryBill"
              v-if="!!isCanGotFin"
              >查询账单终稿</a-button
            >
          </a-col>
          <a-col :md="14" :sm="24" v-show="isOnTime">
            <a-button
              style="margin-left: 8px"
              @click="handleSreachBill"
              v-if="!!isCanCreate"
              >查询账单草稿</a-button
            >
            <a-button
              style="margin-left: 8px"
              @click="createBill"
              v-if="!!isCanCreate"
              >生成账单</a-button
            >
            <a-button
              style="margin-left: 8px"
              @click="sendBillToAjust"
              v-if="!!isCanGive"
              >发给店长</a-button
            >
          </a-col> -->
          <a-col :md="4" :sm="24">
            <a-button style="margin-left: 8px" @click="queryBill"
              >查询账单终稿</a-button
            >
          </a-col>
          <a-col :md="14" :sm="24">
            <a-button style="margin-left: 8px" @click="handleSreachBill"
              >查询账单草稿</a-button
            >
            <a-button style="margin-left: 8px" @click="createBill"
              >生成账单</a-button
            >
          </a-col>
          <a-col :md="6" :sm="24" v-show="isOnTime" class="fr">
            <a-popconfirm
              placement="bottomRight"
              title="确认发送这些数据为最终账单么？"
              @confirm="confirmSendBill"
              okText="Yes"
              cancelText="No"
            >
              <a-button
                style="margin-left: 8px"
                type="primary"
                v-show="!!isCanFin && !dataCanUse"
                >发送账单</a-button
              >
            </a-popconfirm>
            <a-button
              style="margin-left: 8px"
              type="primary"
              v-show="!!dataCanUse"
              >发送账单</a-button
            >
          </a-col>
        </a-spin>
      </a-card>
      <a-card style="margin-bottom: 24px">
        <!-- 展示1 -->
        <a-spin :spinning="dataLoading">
          <a-row class="justifyBox" :gutter="0" style="line-height: 3">
            <div>
              <div class="shs">
                <h4>{{ showResult.facilityCount }}</h4>
                <p>门店</p>
              </div>
            </div>
            <div>
              <div class="shs">
                <h4>{{ showResult.kitchenCount }}</h4>
                <p>档口</p>
              </div>
            </div>
            <div>
              <div class="shs">
                <h4>{{ showResult.actualBillTotalAmount }}</h4>
                <p>原账单总额</p>
              </div>
            </div>
            <div>
              <div class="shs">
                <h4>{{ showResult.adjustBillTotalAmount }}</h4>
                <p>账单调整总额</p>
              </div>
            </div>
            <div>
              <div class="shs">
                <h4>{{ showResult.adjustCount }}</h4>
                <p>调整笔数</p>
              </div>
            </div>
            <div>
              <div class="shs">
                <h4>{{ showResult.billTotalAmount }}</h4>
                <p>实际账单总额</p>
              </div>
            </div>
            <div v-if="showResult.payRate + '' != 'null'">
              <div class="shs">
                <h4 v-if="showResult.payRate < 100" class="red">
                  {{ showResult.payRate }}%
                </h4>
                <h4 v-else >
                  {{ showResult.payRate }}%
                </h4>
                <p>支付率</p>
                <a class="dlex" href="javascript:;" @click="downloadTemplate"
                  >支付详情</a
                >
              </div>
            </div>
          </a-row>
          <a-divider dashed></a-divider>
          <!-- 搜索2 -->
          <a-row :gutter="0" style="line-height: 3" v-if="tableData.length > 0">
            <div class="table-operator" style="margin-bottom: -8px">
              <a-form layout="inline">
                <a-row :gutter="5" style="line-height: 3.5">
                  <a-col :md="6" :sm="24">
                    <a-form-item label="门店编号">
                      <a-input
                        v-model="queryParam.facilityCode"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="门店名称">
                      <a-input
                        style="overflow: hidden; text-overflow: ellipsis"
                        v-model="queryParam.facilityName"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="4" :sm="24">
                    <a-button
                      style="margin-left: 8px"
                      type="primary"
                      @click="() => this.sreachBill()"
                      >搜索</a-button
                    >
                    <a-button
                      style="margin-left: 8px"
                      @click="() => this.resetBill()"
                      >重置</a-button
                    >
                  </a-col>
                  <a-col :md="5" :sm="24" class="fr">
                    <a-upload
                      accept=".xlsx,.xls,.csv"
                      withCredentials
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadCSV"
                      v-if="!!isCanCreate && sreachType != 1"
                    >
                      <a-button style="margin-left: 8px" type="primary"
                        >上传Excel模版</a-button
                      >
                    </a-upload>
                  </a-col>
                  <a-col :md="3" :sm="24" class="fr">
                    <a
                      v-if="!!isCanCreate && sreachType != 1"
                      style="margin-left: 9px;color:royalblue;"
                      href="https://css-2019-shanghai.oss-cn-shanghai.aliyuncs.com/%E7%BB%8F%E8%90%A5%E8%B4%B9%E7%94%A8%E6%A8%A1%E7%89%88.xlsx"
                      download="https://css-2019-shanghai.oss-cn-shanghai.aliyuncs.com/%E7%BB%8F%E8%90%A5%E8%B4%B9%E7%94%A8%E6%A8%A1%E7%89%88.xlsx"
                      >模版</a
                    >
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </a-row>
          <a-divider dashed></a-divider>
          <!-- 上传 -->
          <a-row v-if="tableData.length > 0" :gutter="0" style="line-height: 3">
            <a-row :gutter="5" style="line-height: 3.5">
              <a-col :md="16" :sm="24">
                <div class="upfile" v-for="item in excelList" :key="item.id">
                  <p>{{ item.excelName }}</p>
                  <p>{{ item.adjustAmount }}</p>
                  <p>{{ item.creator }}</p>
                  <p>
                    {{
                      item.createTime
                        ? moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")
                        : ""
                    }}
                  </p>
                  <a-popconfirm
                    placement="topLeft"
                    title="确认清除数据修改么？"
                    @confirm="confirmDeleteCSV(item)"
                    okText="Yes"
                    cancelText="No"
                  >
                    <span v-if="!!isCanCreate">×</span>
                  </a-popconfirm>
                </div>
              </a-col>
            </a-row>
          </a-row>
        </a-spin>
      </a-card>
    </div>
    <!-- 账单显示表格列表 -->
    <!-- 循环开始 -->
    <div>
      <a-card
        v-for="(item, index) in tableData"
        v-bind:key="index"
        style="margin-top: 24px;position:relative;"
      >
        <div class="wrap">
          <span :class="[sreachType == 1 ? 'ribbonYellow' : 'ribbonRed']">{{
            item.tag
          }}</span>
        </div>
        <a-spin :spinning="dataLoading">
          <span class="tag"></span>
          <a-row :gutter="0" style="line-height: 1">
            <a-col :md="5" :sm="24" class="cardBox">
              <h3>
                【 账单周期：<span> {{ item.title }}</span> 】
              </h3>
            </a-col>
          </a-row>
          <a-row :gutter="0" style="line-height: 3">
            <a-col :md="5" :sm="24" class="cardBox">
              <h3>
                <span>{{ item.facilityCode }}</span> {{ item.facilityName }}
              </h3>
            </a-col>
            <a-col :md="3" :sm="24">
              <h3>
                档口数： <span>{{ item.kitchenCount }}</span>
              </h3>
            </a-col>
            <a-col :md="1" :sm="24"> </a-col>
            <a-col :md="4" :sm="24">
              <p>
                原账单总额： <span>{{ item.actualTotalAmount }}</span>
              </p>
            </a-col>
            <a-col :md="4" :sm="24">
              <p>
                账单调整总额： <span>{{ item.adjustTotalAmount }}</span>
              </p>
            </a-col>
            <a-col :md="3" :sm="24">
              <p>
                调整笔数： <span>{{ item.adjustCount }}</span>
              </p>
            </a-col>
            <a-col :md="4" :sm="24">
              <p>
                实际账单总额： <span>{{ item.totalAmount }}</span>
              </p>
            </a-col>
          </a-row>
          <a-row :gutter="0" style="line-height: 3">
            <a-table
              rowKey="id"
              :columns="item.fields"
              :dataSource="item.resetRows"
              :pagination="false"
              :scroll="item.scrollStyle"
              :rowClassName="changeRowClass"
              :customRow="getcustomRowProps"
            >
            </a-table>
            <a-divider dashed></a-divider>
          </a-row>
        </a-spin>
      </a-card>
    </div>
    <!-- 循环结束 -->
  </div>
</template>
<script>
  

import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
import { hasPermissions } from "../../utils/auth";
import { FacilityActions } from "../../utils/actions";
export default {
  name: "financeOperateFee",
  data() {
    return {
      // 搜索
      queryParam: {
        billDate: "",
        momentDate: null,
        cityId: "",
        facilityBrand: "",
        facilityIdList: [],
        allFacility: true
      },
      // 保存查询参数
      exQueryParam: {},
      // 品牌列表
      brandNameList: [],
      // 店铺列表
      storeList: [],
      // loading
      dataLoading: false,
      tableData: [],
      // 数字展示
      showResult: {
        facilityCount: 0,
        kitchenCount: 0,
        billTotalAmount: 0,
        adjustBillTotalAmount: 0,
        actualBillTotalAmount: 0,
        adjustCount: 0,
        payRate: null
      },
      // 表格列表
      excelList: [],
      // isActiveFinData
      isActiveFinData: false,
      // adjust数据源
      adjustList: [],
      // 店铺id
      billIdList: [],
      //是否在可以生成账单的时间内
      isOnTime: false,
      // sreachType 搜索的数据 为什么数据 1 最终账单，2 已生成账单 3.生成账单
      sreachType: "",
      // 账单生成周期 27-05 号可以用
      beforeMonthDay: "26",
      currentMonthDay: "06",
      // 间隔月为1 暂时不可动
      rangeMonth: 1,
      // 是否在可操作时间格式内
      disabledDateTime: moment()
    };
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
    ...mapGetters("users", ["authenticatedUser"]),
    isCanCreate() {
      return hasPermissions(
        [FacilityActions.OPEX_BILL_CREATE],
        this.authenticatedUser.actions
      );
    },
    isCanGotFin() {
      return hasPermissions(
        [FacilityActions.OPEX_BILL_READ],
        this.authenticatedUser.actions
      );
    },
    isCanGive() {
      return hasPermissions(
        [FacilityActions.OPEX_BILL_SEND_MANAGER],
        this.authenticatedUser.actions
      );
    },
    isCanFin() {
      return hasPermissions(
        [FacilityActions.OPEX_BILL_ADJUST],
        this.authenticatedUser.actions
      );
    },
    dataCanUse: function() {
      return this.sreachType != 1 ? false : true;
    }
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    // 现在模版
    downloadTemplate() {
      let data = Object.assign({}, this.exQueryParam);
      axios({
        // GET /fms_css/bill/exportBillListByNotPaid
        // url: "bill/exportBill",
        url:   this.$baseUrl + "bill/exportBillListByNotPaid",
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/vnd.ms-excel;charset=utf-8'"
        },
        params: {
          cityId: data.cityId,
          facilityBrand: data.facilityBrand,
          billDate: data.billDate
        }
      }).then(res => {
        const content = res.data;
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        var date =
          new Date().getFullYear() +
          "" +
          (new Date().getMonth() + 1) +
          "" +
          new Date().getDate();
        let fname = "经营费用支付详情";
        const fileName = date + fname + ".xls";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        if (res.status == 200) {
          this.$message.success("下载成功！");
        } else {
          this.$message.error("下载失败！");
        }
      });
    },
    // 时间函数
    moment,
    // 是否在可以生成账单的时间内
    getIsOntime(cTime) {
      // 获取传入月
      let Y = cTime.format("Y");
      let M = cTime.format("M");
      let Am = cTime.add(1, "months").format("M");
      let Ay = cTime.format("Y");
      if (Am == 1) {
        Ay = Ay * 1 + 1;
      }
      let startTime =
        Y + "-" + (M >= 10 ? M : "0" + M) + "-" + this.beforeMonthDay;
      // 依据当前年当前月获取结束时间
      let endTime =
        Ay + "-" + (Am >= 10 ? Am : "0" + Am) + "-" + this.currentMonthDay;
      //获取当前时间
      let nowTime = moment();
      return true;
      // return this.isIncludeRangeTime(startTime, endTime, nowTime);
    },
    // 是否在时间段内
    isIncludeRangeTime(startTime, endTime, nowTime) {
      // 判断是否在开始之前
      let sKey = moment(nowTime).isBefore(startTime);
      if (sKey) {
        return false;
      }
      // 判断是否在结束之前
      let eKey = moment(nowTime).isBefore(endTime);
      if (eKey) {
        return true;
      }
      return false;
    },
    // 搜索函数
    // 月份搜索函数
    searchMonth(month, monthString) {
      this.queryParam.billDate = monthString;
      this.queryParam.momentDate = moment(monthString);
      this.isOnTime = this.getIsOntime(month);
    },
    // 品牌搜索函数
    searchBrandList(val) {
      this.brandNameList = [];
      this.queryParam.facilityBrand = undefined;
      axios({
        url:   this.$baseUrl + "facilityBrand/selectByIds",
        method: "GET",
        params: {
          cityIds: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.brandNameList = res.data.rows;
        }
      });
    },
    // 获取店铺列表
    searchStoreList(val) {
      this.storeList = [];
      this.queryParam.facilityBrand = val;
      axios({
        url:   this.$baseUrl + "/facility/queryFacilitySelect",
        method: "GET",
        params: {
          facilityBrand: this.queryParam.facilityBrand,
          cityId: this.queryParam.cityId
        }
      }).then(res => {
        if (res.data.rows) {
          this.storeList = res.data.rows;
        }
      });
    },
    // check点选
    checkStore() {
      this.queryParam.allFacility = !this.queryParam.allFacility;
      if (this.queryParam.allFacility) {
        this.queryParam.facilityIdList = [];
      }
    },
    // 生成账单
    createBill() {
      this.dataLoading = true;
      this.showResult = {
        facilityCount: 0,
        kitchenCount: 0,
        billTotalAmount: 0,
        adjustBillTotalAmount: 0,
        actualBillTotalAmount: 0,
        adjustCount: 0,
        payRate: null
      };
      this.tableData = [];
      this.queryParam.facilityCode = "";
      this.queryParam.facilityName = "";
      let sreachData = Object.assign({}, this.queryParam);
      if (!sreachData.billDate) {
        this.$message.error(`请选择时间`);
        this.dataLoading = false;
        return;
      }
      if (!sreachData.cityId) {
        this.$message.error(`请选择城市`);
        this.dataLoading = false;
        return;
      }
      if (!sreachData.facilityBrand) {
        this.$message.error(`请选择品牌`);
        this.dataLoading = false;
        return;
      }
      if (sreachData.allFacility) {
        if (this.storeList.length <= 0) {
          this.$message.error(`该品牌下没有店铺`);
          this.dataLoading = false;
          return;
        }
        let ar = [];
        this.storeList.forEach((ele, index) => {
          ar.push(ele.id);
        });
        sreachData.facilityIdList = ar;
      } else {
        if (sreachData.facilityIdList.length == 0) {
          this.$message.error(`请选择店铺`);
          this.dataLoading = false;
          return;
        }
      }
      // POST /fms_css/bill/queryBillOperationByFina 往期账单
      // bill/queryBillOperation 需要编辑账单
      // POST /fms_css/bill/generateBillOperation
      let postUrl = "bill/generateBillOperation";
      axios({
        url:   this.$baseUrl + postUrl,
        method: "POST",
        data: sreachData,
        timeout: 100000000
      })
        .then(res => {
          if (res.data.success) {
            if (!res.data.obj) {
              if (res.data.returnCode == 200 || res.data.returnCode == 502) {
                this.handleSreachBill();
              } else if (res.data.returnCode == 201) {
                this.$message.error(`符合条件的账单已经被发布！`);
                this.dataLoading = false;
                return;
              } else {
                this.$message.error(`${res.data.returnMsg}`);
                this.dataLoading = false;
                return;
              }
            }
            // 生成账单为3
            // this.sreachType = '3';
            // this.dataInit(res.data.obj);
            // this.dataLoading = false;
          } else {
            this.dataLoading = false;
            this.tableData = [];
            this.showResult = {
              facilityCount: 0,
              kitchenCount: 0,
              billTotalAmount: 0,
              adjustBillTotalAmount: 0,
              actualBillTotalAmount: 0,
              adjustCount: 0,
              showResult: null
            };
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    //查询账单终稿
    queryBill() {
      this.dataLoading = true;
      this.showResult = {
        facilityCount: 0,
        kitchenCount: 0,
        billTotalAmount: 0,
        adjustBillTotalAmount: 0,
        actualBillTotalAmount: 0,
        adjustCount: 0,
        payRate: null
      };
      this.tableData = [];
      this.queryParam.facilityCode = "";
      this.queryParam.facilityName = "";
      let sreachData = Object.assign({}, this.queryParam);
      if (!sreachData.billDate) {
        this.$message.error(`请选择时间`);
        this.dataLoading = false;
        return;
      }
      if (!sreachData.cityId) {
        this.$message.error(`请选择城市`);
        this.dataLoading = false;
        return;
      }
      if (!sreachData.facilityBrand) {
        this.$message.error(`请选择品牌`);
        this.dataLoading = false;
        return;
      }
      if (sreachData.allFacility) {
        if (this.storeList.length <= 0) {
          this.$message.error(`该品牌下没有店铺`);
          this.dataLoading = false;
          return;
        }
        let ar = [];
        this.storeList.forEach((ele, index) => {
          ar.push(ele.id);
        });
        sreachData.facilityIdList = ar;
      } else {
        if (sreachData.facilityIdList.length == 0) {
          this.$message.error(`请选择店铺`);
          this.dataLoading = false;
          return;
        }
      }
      // POST /fms_css/bill/queryBillOperationByFina 往期账单
      // bill/queryBillOperation 需要编辑账单
      // POST /fms_css/bill/generateBillOperation
      let postUrl = "bill/queryBillOperationByFinal";
      axios({
        url:   this.$baseUrl + postUrl,
        method: "POST",
        data: sreachData,
        timeout: 100000000
      })
        .then(res => {
          if (res.data.success) {
            if (!res.data.obj) {
              if (res.data.returnCode == 201) {
                this.$message.error(`没有符合条件的最终账单！`);
                this.dataLoading = false;
                return;
              } else {
                this.$message.error(`${res.data.returnMsg}`);
                this.dataLoading = false;
                return;
              }
            }
            // 查询账单终稿为1
            this.sreachType = "1";
            this.dataInit(res.data.obj);
            this.dataLoading = false;
          } else {
            this.dataLoading = false;
            this.tableData = [];
            this.showResult = {
              facilityCount: 0,
              kitchenCount: 0,
              billTotalAmount: 0,
              adjustBillTotalAmount: 0,
              actualBillTotalAmount: 0,
              adjustCount: 0,
              showResult: null
            };
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 查询未发送的账单
    handleSreachBill() {
      this.dataLoading = true;
      this.showResult = {
        facilityCount: 0,
        kitchenCount: 0,
        billTotalAmount: 0,
        adjustBillTotalAmount: 0,
        actualBillTotalAmount: 0,
        adjustCount: 0,
        payRate: null
      };
      this.tableData = [];
      let sreachData = Object.assign({}, this.queryParam);
      if (!sreachData.billDate) {
        this.$message.error(`请选择时间`);
        this.dataLoading = false;
        return;
      }
      if (!sreachData.cityId) {
        this.$message.error(`请选择城市`);
        this.dataLoading = false;
        return;
      }
      if (!sreachData.facilityBrand) {
        this.$message.error(`请选择品牌`);
        this.dataLoading = false;
        return;
      }
      if (sreachData.allFacility) {
        if (this.storeList.length <= 0) {
          this.$message.error(`该品牌下没有店铺`);
          this.dataLoading = false;
          return;
        }
        let ar = [];
        this.storeList.forEach((ele, index) => {
          ar.push(ele.id);
        });
        sreachData.facilityIdList = ar;
      } else {
        if (sreachData.facilityIdList.length == 0) {
          this.$message.error(`请选择店铺`);
          this.dataLoading = false;
          return;
        }
      }
      // POST /fms_css/bill/queryBillOperation
      let postUrl = "bill/queryBillOperation";
      axios({
        url:   this.$baseUrl + postUrl,
        method: "POST",
        data: sreachData
      })
        .then(res => {
          if (res.data.success) {
            if (!res.data.obj) {
              this.$message.error(`${res.data.returnMsg}`);
              this.dataLoading = false;
              return;
            }
            // 查询账单草稿为2
            this.sreachType = "2";
            this.dataInit(res.data.obj);
            this.dataLoading = false;
          } else {
            this.dataLoading = false;
            this.tableData = [];
            this.showResult = {
              facilityCount: 0,
              kitchenCount: 0,
              billTotalAmount: 0,
              adjustBillTotalAmount: 0,
              actualBillTotalAmount: 0,
              adjustCount: 0,
              showResult: null
            };
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 信息初始化函数
    dataInit(data) {
      // 1 最终账单，2 已生成账单 3.生成账单
      this.resData = {};
      this.resData = data;
      // 清除调整数据凭证
      this.adjustList = [];
      this.billIdList = [];

      let tableData = data.facilityList || [];
      tableData = data.facilityList || [];
      // 生成表格头
      tableData.forEach((columns, index) => {
        if (this.sreachType == 1) {
          columns.title = this.queryParam.billDate;
          columns.tag = "终稿";
        }
        if (this.sreachType == 2) {
          columns.title = this.queryParam.billDate;
          columns.tag = "草稿";
        }
        if (this.sreachType == 3) {
          columns.title = this.queryParam.billDate;
          columns.tag = "草稿";
        }
        // 这里减2为了让滚动出现
        let devicePixelRatio =
          window.devicePixelRatio >= 1 ? 1 : window.devicePixelRatio;
        let width = window.screen.width / devicePixelRatio;
        columns.scrollStyle = { x: width, y: 700 };
        this.handleResetColumns(columns.fields);
      });
      this.initExcelList(data.excelList || []);
      this.handleResetRows(tableData);
      this.tableData = tableData;
      this.changeCountNum(data.stat);
      this.exQueryParam = Object.assign({}, this.queryParam);
      if (this.exQueryParam.allFacility) {
        let ar = [];
        this.storeList.forEach((ele, index) => {
          ar.push(ele.id);
        });
        this.exQueryParam.facilityIdList = ar;
      }
    },
    // 生成表格头
    handleResetColumns(columns) {
      // 头部函数
      let columnFnOne = (text, row, index) => {
        if (!row.id) {
          return {
            children: <span>总计：</span>,
            props: {
              colSpan: 2
            }
          };
        } else {
          return <span>{text}</span>;
        }
      };

      let columnFnTwo = (text, row, index) => {
        if (!row.id) {
          return {
            props: {
              colSpan: 0
            }
          };
        } else {
          return {
            children: <div class="text-wrapper">{text}</div>,
            attrs: {
              title: text,
            }
          };
        };
      };

      let columnFnThree = (text, row, index) => {
        if (!row.id) {
          return {
            props: {
              colSpan: 0
            }
          };
        } else {
          return <span>{text}</span>;
        };
      };

      // pop函数
      let popContent = (text, row, index) => {
        if (!row.remark) {
          return <span>{text}</span>;
        } else {
          return (
            <a-popover placement="top">
              <template slot="content">
                <p>{row.remark}</p>
              </template>
              <a>{text}</a>
            </a-popover>
          );
        }
      };

      columns.forEach(function(element, index) {
        element.align = "center";
        let devicePixelRatio =
          window.devicePixelRatio >= 1 ? 1 : window.devicePixelRatio;
        let w = window.screen.width / devicePixelRatio;
        let width = w / columns.length;
        element.width = width;
        // 加入总计函数
        if (index == 0) {
          element.customRender = columnFnOne;
        } else if (index == 1) {
          element.customRender = columnFnTwo;
          element.width = "240px";
        } else if (index == 2) {
          element.customRender = columnFnThree;
        } else {
          element.customRender = popContent;
        }
      });
    },
    // 生成表格rows
    handleResetRows(info) {
      info.forEach((element, ei) => {
        let columns = element.fields;
        let columnsArr = [];
        columns.forEach((ce, ci) => {
          columnsArr.push(ce.dataIndex);
        });
        let resetRows = [];
        let sid = [];
        element.kitchenList.forEach((item, ii) => {
          let oo = { };
          item.rows.forEach((ei, eii) => {
            // 处理正负号
            oo[ei.field] =
              ei.handleType == 1 ? "-" + ei.fieldValue : ei.fieldValue;
            // cell中的属性添加到表格的列，用于后续的判断
            oo.isDeparture = ei.isDeparture;
          });
          resetRows.push(oo);
          sid.push(oo.id);
          //补足缺失数据
          columnsArr.forEach((cae, caei) => {
            if (!oo[cae]) {
              oo[cae] = "0.00";
            }
          });
          // 处理多余行数
          if (item.adjust.length > 0) {
            item.adjust.forEach((exo, exii) => {
              let eo = Object.assign({}, exo);
              eo[exo.field] =
                exo.handleType == 1 ? "-" + exo.fieldValue : exo.fieldValue;
              resetRows.push(eo);
              this.adjustList.push({
                billId: oo.id,
                adjustId: eo.id,
                source: eo.source
              });
            });
          }
        });
        this.billIdList = this.billIdList.concat(sid);
        // 计算总计
        this.computerTotal(resetRows);
        element.resetRows = resetRows;
      });
      return;
    },
    // 补全缺失
    // 改变行颜色
    changeRowClass(record, index) {
      if (record.approval == 2) {
        return "yRowStyl";
      } else if (record.approval == 3 || record.approval == 1) {
        return "gRowStyl";
      } else if (record.isDeparture === 1) {
        return "gRowStyl mouse-pointer";
      }
      return;
    },
    getcustomRowProps(record) {
      if(record.isDeparture === 1) {
        return {
          attrs: {
            title: "已计入离场结算账单，该账单仅参与公摊计算，不会发出",
          },
        };
      }
    },
    // 计算总计
    computerTotal(rows) {
      let total = {};
      rows.forEach((element, index) => {
        let obj = Object.assign({}, element);
        delete obj.id;
        delete obj.cityId;
        delete obj.facilityId;
        delete obj.kitchenId;
        delete obj.source;
        delete obj.field;
        delete obj.remark;
        delete obj.approval;
        delete obj.handleType;
        delete obj.fieldValue;
        delete obj["档口"];
        delete obj["店铺名称"];
        for (let k in obj) {
          if (total.hasOwnProperty(k)) {
            total[k] = (total[k] * 1 + obj[k] * 1).toFixed(2);
          } else {
            total[k] = (obj[k] * 1).toFixed(2);
          }
        }
      });
      total.id = undefined;
      rows[rows.length] = total;
    },
    // 展示卡片
    changeCountNum(data) {
      this.showResult = data;
    },
    // 搜索数据
    sreachBill() {
      if (!!this.queryParam.facilityCode || !!this.queryParam.facilityName) {
        // 1 最终账单，2 已生成账单 3.生成账单
        if (this.sreachType == 1) {
          // this.handleSreachBill();
          // 搜索最终账单
          this.queryBill();
        } else {
          // 搜索已经生成的账单
          this.handleSreachBill();
        }
        // this.handleSreachBill();
      } else {
        this.$message.error(`请输入搜索条件！`);
        return;
      }
    },
    // 重置数据
    resetBill() {
      if (this.exQueryParam.allFacility) {
        this.exQueryParam.facilityIdList = [];
      }
      this.queryParam = Object.assign({}, this.exQueryParam);
      this.queryParam.facilityCode = "";
      this.queryParam.facilityName = "";
      this.createBill();
    },
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    // CSV上传
    uploadCSV({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            if (element.response.returnCode == "200") {
              this.afterUploadCSV(element);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              this.dataLoading = false;
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // afterUploadCSV
    afterUploadCSV(info) {
      let data = Object.assign({}, this.exQueryParam);
      data.attachmentName = info.name;
      data.url = info.response.obj;
      data.billType = 5;
      // POST /fms_css/bill/uploadBillCostAdjust
      axios({
        url:   this.$baseUrl + "bill/uploadBillCostAdjust",
        method: "POST",
        data: data
      })
        .then(res => {
          if (res.data.success) {
            this.sreachResetBill();
          } else {
            this.dataLoading = false;
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // CSVreset Data
    sreachResetBill() {
      this.sreachType = 2;
      this.dataLoading = true;
      let sreachData = Object.assign({}, this.exQueryParam);
      if (this.exQueryParam.allFacility) {
        this.exQueryParam.facilityIdList = [];
      }
      this.queryParam = Object.assign({}, this.exQueryParam);
      // POST /fms_css/bill/queryBillOperation
      let postUrl = "bill/queryBillOperation";
      axios({
        url:   this.$baseUrl + postUrl,
        method: "POST",
        data: sreachData
      })
        .then(res => {
          if (res.data.success) {
            if (!res.data.obj) {
              this.$message.error(`${res.data.returnMsg}`);
              this.dataLoading = false;
              return;
            }
            this.dataInit(res.data.obj);
            this.dataLoading = false;
          } else {
            this.dataLoading = false;
            this.tableData = [];
            this.showResult = {
              facilityCount: 0,
              kitchenCount: 0,
              billTotalAmount: 0,
              adjustBillTotalAmount: 0,
              actualBillTotalAmount: 0,
              adjustCount: 0,
              showResult: null
            };
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // initExcelList
    initExcelList(info) {
      this.excelList = info.length != 0 ? info : [];
    },
    // 发送给店长
    sendBillToAjust() {
      if (this.sreachType == 1) {
        this.$message.error(`数据为最终账单数据不可操作！`);
        this.dataLoading = false;
        return;
      }
      // POST /fms_css/bill/adjustBillOperation
      if (this.tableData.length == 0) {
        this.$message.error(`没有可发送的数据！`);
        return;
      }
      if (!this.resData.facilityList || this.resData.facilityList.length == 0) {
        this.$message.error(`没有可发送的数据！`);
        return;
      }
      let obj = Object.assign({}, this.exQueryParam);
      // ajust来源1 / 2
      obj.adjustList = this.adjustList;
      obj.billIdList = this.billIdList;
      axios({
        url:   this.$baseUrl + "bill/adjustBillOperation",
        method: "POST",
        data: obj
      })
        .then(res => {
          if (res.data.success) {
            if (this.exQueryParam.allFacility) {
              this.exQueryParam.facilityIdList = [];
            }
            this.queryParam = Object.assign({}, this.exQueryParam);
            this.createBill();
            this.$message.info(`发送成功！`);
          } else {
            this.dataLoading = false;
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // confirmSendBill 发送最终账单
    confirmSendBill() {
      if (this.sreachType == 1) {
        this.$message.error(`数据为最终账单数据不可操作！`);
        this.dataLoading = false;
        return;
      }
      // POST /fms_css/bill/finalBillOperation
      if (this.tableData.length == 0) {
        this.$message.error(`没有可发送的数据！`);
        this.dataLoading = false;
        return;
      }
      if (!this.resData.facilityList || this.resData.facilityList.length == 0) {
        this.$message.error(`没有可发送的数据！`);
        this.dataLoading = false;
        return;
      }
      // 判断两个ajust 来源。1. tenant_cost，bill_cost_adjust
      let obj = Object.assign({}, this.exQueryParam);
      // ajust来源1 / 2
      obj.adjustList = this.adjustList;
      obj.billIdList = this.billIdList;
      axios({
        url:   this.$baseUrl + "bill/finalBillOperation",
        method: "POST",
        data: obj
      })
        .then(res => {
          if (res.data.success) {
            // 修改账单状态
            this.sreachType = "1";
            this.dataInit(res.data.obj);
            this.isActiveFinData = true;
            this.dataLoading = false;
            this.$message.info(`发送成功！`);
          } else {
            this.dataLoading = false;
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 删除调整表格
    confirmDeleteCSV(info) {
      // POST /fms_css/bill/removeBillCostAdjustByAttachmentId
      // attachmentId
      this.dataLoading = true;
      var formData = new FormData();
      formData.append("attachmentId", info.id * 1);
      let accessToken = localStorage.getItem("token");
      let mid = sessionStorage.getItem("midKey");
      fetch(  this.$baseUrl + "bill/removeBillCostAdjustByAttachmentId", {
        method: "POST",
        headers: {
          Accept: "*/*",
          "x-requested-with": JSON.stringify({
            accessKey: accessToken,
            mid: mid
          })
        },
        withCredentials: true,
        body: formData
      })
        .then(res => {
          if (res.status == "200") {
            this.sreachResetBill();
          } else {
            this.dataLoading = false;
            this.$message.error(`操作失败！`);
          }
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // resetPage
    resetPage() {
      this.queryParam = {
        cityId: "",
        facilityBrand: "",
        facilityIdList: [],
        allFacility: true
      };

      this.exQueryParam = {};
      this.tableData = [];
      // 数字展示
      this.showResult = {
        facilityCount: 0,
        kitchenCount: 0,
        billTotalAmount: 0,
        adjustBillTotalAmount: 0,
        actualBillTotalAmount: 0,
        adjustCount: 0
      };
      // isActiveFinData
      this.isActiveFinData = false;
      // adjust数据源
      this.adjustList = [];
      this.billIdList = [];
    },
    // 时间禁用
    disabledDate(current) {
      // 判断年份是否可用
      if (current.year() > this.disabledDateTime.year()) {
        return true;
      } else if (current.year() < this.disabledDateTime.year()) {
        return false;
      }
      // 判断月份是否可用
      let isAddMonth =
        this.disabledDateTime.format("D") * 1 < this.beforeMonthDay * 1;
      let month = isAddMonth
        ? moment().month()
        : moment().month() + this.rangeMonth;
      // Can not select days before today and today
      let mk = current.month() && current.month() >= month;
      return mk;
    }
  },
  created() {
    this.getUserCities();
  }
};
</script>
<style lang="less">
.yRowStyl {
  background-color: rgb(252, 230, 190);
}
.gRowStyl {
  background-color: rgb(215, 215, 215);
}
.mouse-pointer {
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.justifyBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  > div {
  }
}
.cardBox {
  position: relative;
  .cardBoxTitle {
    position: absolute;
    width: 18em;
    top: -1.5em;
    left: 0em;
  }
}
.shs {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .green {
    color: green;
  }
  > h4 {
    font-size: 2em;
    font-weight: 500;
    height: 1.5em;
  }
  > p {
    font-size: 1.2em;
    text-align: center;
  }
  .dlex {
    position: absolute;
    width: 5em;
    bottom: -1em;
    text-decoration: underline;
  }
}
.red {
  color: red;
}
.upfile {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 3;
  > a {
    color: rgb(85, 163, 200);
    text-decoration: underline;
  }
  p {
    color: rgb(85, 163, 200);
    margin-left: 1.2em;
    margin-bottom: 0;
    line-height: 3;
    text-decoration: underline;
  }
  span {
    margin-left: 1.5em;
    cursor: pointer;
    font-size: 1.5em;
    line-height: 1.5em;
  }
}
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.wrap {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.ribbonRed {
  display: inline-block;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: -55px;
  z-index: 100;
  overflow: hidden;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  background: rgba(246, 188, 112, 1);
  color: #fff;
}
.ribbonYellow {
  display: inline-block;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: -55px;
  z-index: 100;
  overflow: hidden;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: #fff;
  background: rgba(186, 35, 107, 1);
}
.text-wrapper {
  width: 200px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
}
</style>
